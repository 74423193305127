import React from 'react';
import Layout from '../layouts/Layout';
import styled from '@emotion/styled';
import {
  PricelistModel,
  ProductModel,
  ProductPropertyModel,
  ReviewGroupModel,
  VariantModel,
} from '@nimles/models';
import { ReviewGroupPage } from '../components/reviewGroup/page/ReviewGroupPage';
import { graphql } from 'gatsby';
import { ReviewWithProduct } from '../types';

const Logo = styled.img`
  margin-bottom: 30px;
  width: 120px;
`;

const Review = ({ pageContext, location }) => {
  const { href } = location;
  const {
    reviewGroup,
    reviews,
    productProperties,
    pricelists,
    variant,
  }: {
    reviewGroup: ReviewGroupModel;
    reviews: ReviewWithProduct[];
    productProperties: ProductPropertyModel[];
    pricelists: PricelistModel[];
    variant: VariantModel;
  } = pageContext;
  const { title, summary, tags } = reviewGroup;

  // const banner = getImageUrl(bannerId, 1920);

  const meta = {
    title,
    description: summary,
    // image: banner,
    keywords: tags,
  };

  return (
    <Layout
      header
      footer
      meta={meta}
      location={location}
      headerAlwaysOpen={true}
    >
      <ReviewGroupPage
        reviewGroup={reviewGroup}
        reviews={reviews}
        pricelists={pricelists}
        productProperties={productProperties}
        variant={variant}
        location={location}
      />
    </Layout>
  );
};

export default Review;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
