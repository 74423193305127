import styled from '@emotion/styled';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const BaseContainer = styled.div`
  position: relative;
`;

const ScoreContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: ${({ theme }) => theme.colors.primary.color};
`;

interface Props {
  score: number;
  base?: number;
  size?: SizeProp;
}

export const ReviewScore = ({ score = 0, base = 10, size = 'lg' }: Props) => {
  const numberOfStars = Math.floor((score * 5) / base);
  const baseArray = [...Array(5).keys()];
  const scoreArray = [...Array(numberOfStars).keys()];
  const halfStar = ((score * 5) % base) / base >= 0.5;

  console.log(
    'Score',
    base,
    score,
    numberOfStars,
    halfStar,
    baseArray,
    scoreArray
  );
  return (
    <BaseContainer>
      {baseArray.map(() => (
        <FontAwesomeIcon icon={faStar} size={size} />
      ))}
      <ScoreContainer>
        {scoreArray.map(() => (
          <FontAwesomeIcon icon={faStar} size={size} />
        ))}
        {halfStar ? <FontAwesomeIcon icon={faStarHalf} size={size} /> : null}
      </ScoreContainer>
    </BaseContainer>
  );
};
