import styled from '@emotion/styled';
import {
  ProductPropertyModel,
  ReviewGroupModel,
  VariantModel,
} from '@nimles/models';
import { PricelistPublicModel } from '@nimles/models/dist/public/ecommerce';
import { Column, Container, Row, Section } from '@nimles/react-web-components';
import React from 'react';
import { ReviewCard } from '../../review/card/ReviewCard';
import { ReviewWithProduct } from '../../../types';
import { getImageUrl } from '../../../utils';

const SectionOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
`;

interface Props {
  reviewGroup: ReviewGroupModel;
  reviews: ReviewWithProduct[];
  productProperties: ProductPropertyModel[];
  pricelists: PricelistPublicModel[];
  location: Location;
  variant: VariantModel;
}

export const ReviewGroupPage = ({ reviewGroup, reviews }: Props) => {
  return (
    <>
      <Section
        backgroundColor="#075384"
        color="#fff"
        backgroundImage={getImageUrl(reviewGroup.bannerId)}
      >
        <SectionOverlay>
          <Container minHeight="30vh" justify="center" padding="100px 15px">
            <h1>{reviewGroup.title}</h1>
            <p>{reviewGroup.summary}</p>
          </Container>
        </SectionOverlay>
      </Section>
      <Section>
        <Container padding="100px 15px">
          {reviews.map((review) => (
            <ReviewCard
              review={review}
              productProperties={[]}
              pricelists={[]}
            />
          ))}
        </Container>
      </Section>
      <Section>
        <Container>
          <Row wrap="wrap">
            <Column xs={100} md={50} lg={40} justify="center"></Column>
          </Row>
        </Container>
      </Section>
    </>
  );
};
