import styled from '@emotion/styled';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductPropertyModel } from '@nimles/models';
import { PricelistPublicModel } from '@nimles/models/dist/public/ecommerce';
import {
  Button,
  Card,
  CardBody,
  Flex,
  Image,
} from '@nimles/react-web-components';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { ReviewWithProduct } from '../../../types';
import { ReviewScore } from '../score/ReviewScore';

const AffiliateLogo = styled.img`
  width: 100%;
`;

interface ArgumentProps {
  sign: 'pro' | 'con';
  text: string;
}

const StyledArgument = styled.div`
  svg {
    margin-right: 10px;
  }
`;

const LinkButton = Button.withComponent(Link);

interface Props {
  review: ReviewWithProduct;
  productProperties: ProductPropertyModel[];
  pricelists: PricelistPublicModel[];
}

export const ReviewCard = ({ review, productProperties }: Props) => {
  const affiliates = [{}, {}, {}, {}];

  const imageId =
    review.imageIds?.find((i) => i) ?? review.product.imageIds?.find((i) => i);

  console.log('review', review);

  return (
    <Card>
      <CardBody>
        <Flex container wrap="wrap">
          <Flex item xs={100} md="0 0 300px">
            {imageId && (
              <Image
                src={`${process.env.GATSBY_TENANT}/${imageId}`}
                alt={review.title}
              />
            )}
          </Flex>
          <Flex item xs={100} md="flex">
            <Flex container alignItems="center" wrap="wrap">
              <Flex item xs={100} md="flex">
                <h3>{review.title}</h3>
              </Flex>
              <Flex item xs={100} lg="auto">
                <ReviewScore score={review.score?.value} />
              </Flex>
            </Flex>
            <p>{review.summary}</p>
            <div>
              <LinkButton primary raised to={`/${review.uniqueName}-r`}>
                Läs mer
              </LinkButton>
            </div>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};
